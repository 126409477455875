<template>
    <div style="display: flex;align-items: center;justify-content: center">
        <div class="box_wrap" style="width: 80%;height: 80%">

        </div>
    </div>
</template>

<script>
export default {
	name: "trademarks",
    mounted() {
		window.open('https://www.uspto.gov/trademarks','mark','width=1540px,height=808px,left=280px,top=200px')
    }
}
</script>

<style scoped>

</style>
